import React, { Fragment, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import RenderForm from 'gatsby-plugin-cloudofertas'

/* COMPONENTS */
import Pagina from '../components/Pagina'

/* STYLES */
import './convenio.css'

export default function convenioEmpresarial () {
  const data = useStaticQuery(graphql`
    query {
      pagina:contentfulPagina(path: { eq: "/convenio-empresarial/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
      cloudOfertasForm(name: {eq: "SEJA_UM_CONVENIADO"}) {
        id
        formTypeField {
          name
          type
          required
          style
          length
          label
          formLov {
            formLovData {
              id
              value
              createdDate
            }
          }
        }
      }
    }
  `
  )
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const getWatch = (data) => {
    if (data.convenio === 'Pessoa Jurídica') {
      document.getElementById('empresa').parentElement.classList.remove('d-none')
      document.getElementById('empresa').parentElement.classList.add('d-flex')
    } else {
      document.getElementById('empresa').parentElement.classList.remove('d-flex')
      document.getElementById('empresa').parentElement.classList.add('d-none')
    }
  }

  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <section id="image-convenio" className="position-relative">
        <div id="img" className="w-100"></div>
        <div className="container">
          <div className="row">
            <div id="figcaption" className="col-10 col-md-7 col-lg-5 p-md-0">
              <h1 className="theme-secundary">CONVÊNIO EMPRESARIAL</h1>
              <h2 className="theme-primary">TRAGA A SUA EMPRESA PARA O GRUPO DE CONVENIADOS REDE NOVA FARMA!</h2>
              <p className="text-left my-md-4">
                O convênio Rede Nova Farma
                <br className="d-md-none" /> proporciona tranquilidade e comodidade
                <br className="d-md-none" /> ao colaborador da sua empresa,
                <br className="d-md-none" /> disponibilizando produtos e
                <br className="d-md-none" /> serviços debitados
                <br className="d-md-none" /> diretamente em
                <br className="d-md-none" /> folha de pagamento.
              </p>
              <a href="#section2" id="btnLink" title="Preencha o formulário de cadastro de convênio" className="d-flex justify-content-center align-items-center theme-border-2 btn theme--secundary text-white">PREENCHA O FORMULÁRIO DE CADASTRO</a>
            </div>
          </div>
        </div>
      </section>
      <section id="moreInformations">
        <div className="collaborator theme--quaternary py-1">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="m-4 m-lg-5 theme-primary text-center">BENEFÍCIOS DO PROGRAMA DE CONVÊNIO EMPRESARIAL</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h3 className="theme-secundary text-center">COLABORADOR</h3>
              </div>
            </div>
            <div className="row collaborator-items d-md-flex flex-md-justify-start align-items-md-start text-left pt-5 pb-1 py-md-0 pt-md-5">
              <div className="col-10 col-md-6 col-lg-4">
                <p className="my-0 ml-5 pl-md-1 pl-lg-5">Desconto em folha de pagamento</p>
              </div>
              <div className="col-10 col-md-6 col-lg-4">
                <p className="my-0 ml-5 pl-md-1 pl-lg-5">Entrega rápida na residência ou trabalho* <br /><small>*consulte área de cobertura na loja</small></p>
              </div>
              <div className="col-10 col-md-6 col-lg-4">
                <p className="my-0 mt-3 mt-md-0 ml-5 pl-md-1 pl-lg-5">Farmácia de manipulação</p>
              </div>
              <div className="col-10 col-md-6 col-lg-4">
                <p className="my-0 ml-5 pl-md-1 pl-lg-5">Descontos especiais</p>
              </div>
              <div className="col-10 col-md-6 col-lg-4">
                <p className="my-0 ml-5 pl-md-1 pl-lg-5">Assistência farmacêutica</p>
              </div>
              <div className="col-10 col-md-6 col-lg-4">
                <p className="my-0 ml-5 pl-md-1 pl-lg-5">Rede ampla, com diversos endereços para realização de compras</p>
              </div>
            </div>
          </div>
        </div>
        <div className="company py-1">
          <div className="container">
            <div className="row my-4">
              <div className="col-12">
                <h3 className="theme-secundary text-center">EMPRESA</h3>
              </div>
            </div>
            <div className="row company-items d-md-flex flex-md-justify-start align-items-md-start text-left py-5 pb-5 mb-5 py-md-0 pt-md-5">
              <div className="col-10 col-md-6 col-lg-4">
                <p className="my-0 ml-5 pl-md-1 pl-lg-5">Serviço 100% gratuito</p>
              </div>
              <div className="col-10 col-md-6 col-lg-4">
                <p className="my-0 ml-5 pl-md-1 pl-lg-5">Definimos o limite máximo de compra para cada colaborador</p>
              </div>
              <div className="col-10 col-md-6 col-lg-4">
                <p className="my-0 ml-5 pl-md-1 pl-lg-5">Definimos a data de fechamento</p>
              </div>
              <div className="col-10 col-md-6 col-lg-6">
                <p className="my-0 ml-5  pl-md-1 pl-lg-5">Podemos solicitar, sempre que necessário, os relatórios de compras dos colaboradores</p>
              </div>
              <div className="col-10 col-md-11 col-lg-6">
                <p className="my-0 mt-3 mt-md-0 ml-5 pl-md-1 pl-lg-5">Definimos os critérios para identificação dos colaboradores, bem como a relação de dependentes autorizados para utilização dos benefícios</p>
              </div>
            </div>
          </div>
        </div>
        <div id="section2" className="theme--quaternary py-1 pb-4">
          <div className="container">
            <div className="row mt-4">
              <div className="col-12">
                <h3 className="theme-secundary text-center">SEJA UM CONVENIADO</h3>
              </div>
            </div>
          </div>
          <div>
            { data.cloudOfertasForm.formTypeField && submitSuccess === false &&
              <RenderForm
                setSuccessSubmit={setSubmitSuccess}
                getWatch={getWatch}
                formData={data.cloudOfertasForm}
                placeholder={true}
                containerStyle='container'
                rowStyle='row'
                inputStyle='custom-form d-flex flex-column mt-3'
                btnContainerStyle='custom-form col-12'
                btnStyle='btn btn-block theme--secundary btn-outline-#1A3797 theme-border-2 text-white my-3'
                btnName='ENVIAR'
              />
            }
            {submitSuccess &&
              <div className='container mt-4'>
                <div className='row'>
                  <div className='col'>
                    <div id="alertSucesso" className="alert alert-success" role="alert">
                      Obrigado por entrar em contato com a gente. Responderemos em até 48h.
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    </Fragment>
  )
}
